<template>
  <dashboard-layout>
    <template #content>
      <!-- page header component -->
      <div class="level dashboard-navbar">
        <div class="level-left">
          <div class="level-item">
            <div class="content">
              <h1 class="title has-text-primary">
                Assign Users to
              </h1>
            </div>
          </div>

          <div class="level-item">
            <b-dropdown
              v-model="selectedRole"
              aria-role="list"
            >
              <button
                class="button is-white is-capitalized"
                slot="trigger"
                slot-scope="{ active }"
              >
                <span>
                  {{
                    Object.keys(selectedRole).includes('name') ?
                      $options.filters.role(selectedRole.name) : 'Please select a role'
                  }}
                </span>
                <b-icon
                  :icon="active ? 'chevron-up' : 'chevron-down'"
                  size="is-small"
                />
              </button>

              <b-dropdown-item
                v-for="role in roles"
                :key="role.id"
                :value="role"
                aria-role="listitem"
                class="is-capitalized"
              >
                {{ role.name | role }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <section class="section">
        <div class="container">
          <div class="content">
            <h3 class="subtitle has-text-info">
              Assign Users to Role
            </h3>
          </div>

          <roles-assignment-table
            :loading="loading"
            :users="users"
            :selected-role="selectedRole"
            :current-record="0"
            :last-record="0"
            :total-records="0"
          />
        </div>
      </section>
    </template>
  </dashboard-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { DashboardLayout } from '@/layouts';
import { RolesAssignmentTable } from '@/components/Roles';

export default {

  name: 'RolesAssignment',

  components: {
    DashboardLayout,
    RolesAssignmentTable,
  },

  data: () => ({
    selectedRole: {},
  }),

  computed: mapGetters({
    roles: 'Roles/getRoles',
    loading: 'Users/getLoading',
    users: 'Users/getUsers',
  }),

  filters: {
    role(value) {
      return value.replace(/_/g, ' ').substring(5).toLowerCase();
    },
  },

  async created() {
    try {
      await this.$store.dispatch('Roles/fetchRoles');
      await this.$store.dispatch('Users/fetchUsers');
      return this.$buefy.notification.open({
        message: 'Successfully retrieved all users and roles',
        type: 'is-success',
      });
    } catch (error) {
      return this.$buefy.notification.open({
        message: error.message,
        type: 'is-warning',
      });
    }
  },

};
</script>

<style>

</style>
